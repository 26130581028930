import React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  Container,
  CheckoutButton,
  SampleButton
} from 'components/common';
import HomeLink from 'components/theme/Header/Navbar/HomeLink';
import { Header, HeaderContent, Nav, NavList, PostBody } from './data-styles';
import { Wrapper } from '../components/theme/Header/Navbar/styles';
import { defaultTitle } from '../../site-config';

const DataTemplate = ({ data, pageContext, location }) => {
  const { previous, next } = pageContext;
  const dataPage = data.markdownRemark;
  return (
    <Layout>
      <SEO title={`${dataPage.frontmatter.title} | ${defaultTitle}`} />
      <Header>
        <Container>
          <HeaderContent as={Wrapper}>
            <HomeLink />
          </HeaderContent>
        </Container>
      </Header>
      <Container>
        <Wrapper style={{ flexWrap: 'wrap' }}>
          <h1 style={{ margin: 0 }}>{dataPage.frontmatter.title}</h1>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <SampleButton
              sampleFileName={dataPage.frontmatter.sampleFileName}
            />
            <CheckoutButton
              price={dataPage.frontmatter.price}
              sku={dataPage.frontmatter.sku}
              baseUrl={location.origin}
              currentUrl={location.href}
            />
          </div>
        </Wrapper>
        <PostBody dangerouslySetInnerHTML={{ __html: dataPage.html }} />
      </Container>

      <Container>
        <Nav>
          <NavList>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel='prev'>
                  {`← ${previous.frontmatter.title}`}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel='next'>
                  {`${next.frontmatter.title} →`}
                </Link>
              )}
            </li>
          </NavList>
        </Nav>
      </Container>
    </Layout>
  );
};

export default DataTemplate;

export const pageQuery = graphql`
  query DataPageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        price
        sku
        sampleFileName
      }
    }
  }
`;
